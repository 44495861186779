import { computed, ssrRef } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';

export const useJobs = (id) => {
  const { $softgarden } = useVSFContext();
  const jobs = ssrRef([], `useJobs-jobs-${id}`);
  const loading = ssrRef(false, 'useJobs-loading');
  const error = ssrRef({search: null}, 'useJobs-error');

  /**
   * Searches for jobs
   */
  const search = async (params) => {
    console.debug('useJobs/search', params);

    try {
      loading.value = true;
      error.value.search = null;

      jobs.value = await $softgarden.api.searchJobs(params);
    } catch (err) {
      error.value.search = err.message;
    } finally {
      loading.value = false;
    }
  };

  const searchJobById = async (jobId) => {
    await search(jobId);

    if (Array.isArray(jobs.value) && jobs.value.length >= 1) {
      jobs.value = jobs.value.filter(job => parseInt(job.jobDbId) === parseInt(jobId))[0];
    }
  };

  const getFilteredJobs = async (params) => {
    return await $softgarden.api.searchJobs(params);
  };

  return {
    search,
    searchJobById,
    getFilteredJobs,
    jobs: computed(() => jobs.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
  };
};
