

export default {
  name: 'TheBackToTopButton',
  props: {
    scrollLimit: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      isVisible: false,
      counter: 0,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll: function() {
      this.isVisible = document.body.scrollTop > this.scrollLimit || document.documentElement.scrollTop > this.scrollLimit;
    },

    scrollToTop: function() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
  },
};
