
import type = Mocha.utils.type;

export default {
  name: 'BaseBottomBarMobile',
  props: {
    icons: Array,
  },
  data() {
    return {
      selectedIndex: undefined,
      isHidden: false,
      scrollPosition: 0,
      pathname: '',
    };
  },
  mounted() {
    window.addEventListener('resize', this.deselectIcon);
    window.addEventListener('scroll', this.onScroll);
    this.pathname = window.location.pathname;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.deselectIcon);
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onIconClick: function(index) {
      this.selectedIndex = index;

      this.$emit('onAction', this.icons[index].action);
    },
    deselectIcon: function() {
      this.selectedIndex = undefined;
    },
    onScroll: function() {
      let currentScrollPosition = window.scrollY;

      this.isHidden = currentScrollPosition > this.scrollPosition;
      if (this.isHidden) {
        document.querySelector('.cookiefirst-root:not(#cookiefirst-root)')?.classList.add('push-up');
      } else {
        document.querySelector('.cookiefirst-root:not(#cookiefirst-root)')?.classList.remove('push-up');
      }

      this.scrollPosition = window.scrollY;
    },
    isLinkActive: function(url) {
      if (url === undefined || this.selectedIndex !== undefined) {
        return false;
      }
      let regex = new RegExp(url+'\\b', 'gmi');
      return this.pathname.match(regex)?.length > 0;
    },
  },
};
