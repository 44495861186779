
import {useContent} from '@vue-storefront/storyblok';
import {computed} from '@nuxtjs/composition-api';

import RenderContent from '@/components/RenderContent.vue';

export default {
  components: {
    RenderContent,
  },
  layout: 'default',
  props: {
    error: {
      type: Object,
      default() {
        return { message: '', path: '', statusCode: 0};
      },
    },
  },
  setup() {
    const {
      search: sbSearch,
      content: sbContent,
    } = useContent();
    const story = computed(() => sbContent.value);

    return {
      content: story,
      sbSearch,
    };
  },
  mounted() {
    this.getContent();
  },
  methods: {
    async getContent() {
      await this.sbSearch({url: 'static/404', cache: false});
    },
  },
};
