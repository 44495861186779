import { render, staticRenderFns } from "./default.vue?vue&type=template&id=174ce27a&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeaderNavigation: require('/opt/atlassian/pipelines/agent/build/components/TheHeaderNavigation.vue').default,TheBackToTopButton: require('/opt/atlassian/pipelines/agent/build/components/TheBackToTopButton.vue').default,TheFooter: require('/opt/atlassian/pipelines/agent/build/components/TheFooter.vue').default})
