
import { extractComponents, StoryblokComponent } from '@/helpers/storyblok/storyblok';

export default {
  name: 'RenderContent',
  props: {
    content: Object,
  },
  computed: {
    components(): StoryblokComponent[] {
      if (Array.isArray(this.content)) {
        return;
      }

      return extractComponents(this.content.content);
    },
  },
};
