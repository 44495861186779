
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'BaseIcon',
  components: {
    InlineSvg,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },

    // Width and height to override the size of the svg. undefined = default of the svg
    height: {
      type: String,
      default: undefined,
    },
    width: {
      type: String,
      default: undefined,
    },
  },
};
