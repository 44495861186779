/**
 * Referer of storyblok used for the live preview
 */
export const STORYBLOK_REFERER = 'app.storyblok.com';

/**
 * Type for a storyblok asset
 */
export type StoryblokAsset = {
  url: string;
  alt: string;
  name: string;
  title: string;
  copyright: string;
};

/**
 * Type for a storyblok table
 */
export type StoryblokTable = {
  columns: Array<string>;
  rows: Array<Array<string>>;
};

/**
 * Type for a storyblok link
 */
export type StoryblokLink = {
  href: string;
  url: string;
  linkType: string;
  cachedUrl: string;
};

/**
 * Raw storyblok component as the api returns it
 */
export type RawStoryblokComponent = {
  component: string;
  [key: string]: any;
};

/**
 * Parsed storyblok component
 */
export type StoryblokComponent = {
  componentName: string;
  props: { [key: string]: Array<Object> };
  slots: { [key: string]: Array<StoryblokComponent> };
};

/**
 * Recursively extracts storyblok components, separates block-collections into slots with the
 * corresponding name and transforms some fields into defined types
 *
 * @param content
 */
export function extractComponents (
  content,
): StoryblokComponent[] {
  if (content === void 0) {
    content = [];
  }

  // Required for storyblok bridge to work properly
  if (!Array.isArray(content)) {
    if (!content.hasOwnProperty('content')) {
      return [];
    }

    content = content.content;
  }

  // Filter entries for timed publishing
  content = content.filter(function(component) {
    return checkTimedPublishingFields(component);
  });

  return content.map(function (component) {
    const props = {};
    const slots = {};

    Object.keys(component).forEach(function (key) {
      // Ignore _uid and component fields
      if (key === '_uid' || key === 'component') {
        return;
      }

      // Transform fields
      const prop = transformStoryblokField(component[key]);

      // If we have a collection of blocks, also add it as a slot with the same key as the name
      if (
        Array.isArray(prop) &&
        prop.length > 0 &&
        prop[0].hasOwnProperty('componentName')
      ) {
        slots[key] = {
          content: component[key],
        };
      }

      props[key] = prop;
    });

    return {
      componentName: component.component || 'CustomComponent',
      props,
      slots,
    };
  });
}

/**
 * Transforms a single storyblok field. If the field contains components, those get recursively extracted.
 * If the field contains a fieldtype, the content is transformed into defined types
 *
 * @param field
 */
function transformStoryblokField (field: any): any {
  // Possible block collection
  if (Array.isArray(field) && field.length > 0) {
    const firstEntry = field[0];

    if (firstEntry.hasOwnProperty('component')) {
      return extractComponents(field);
    }
  }

  if (field.hasOwnProperty('fieldtype')) {
    return transformStoryblokFieldByType(field, field.fieldtype);
  }

  return field;
}

/**
 * Transforms storyblok types into defined ts types
 *
 * @param field
 * @param fieldType
 */
function transformStoryblokFieldByType (field: any, fieldType: string): any {
  switch (fieldType) {
    case 'link':
    case 'multilink':
      return {
        href: (field.linktype === 'story' ? '/' : '') + field.cached_url,
        url: field.url,
        linkType: field.linktype,
        cachedUrl: field.cached_url,
      } as StoryblokLink;

    case 'asset':
      return {
        url: field.filename,
        alt: field.alt,
        name: field.name,
        title: field.title,
        copyright: field.copyright,
      } as StoryblokAsset;

    case 'table':
      const columns = field.thead.map(function (column): string {
        return column.value;
      });

      const rows = field.tbody.map(function (row): Array<string> {
        return row.body.map(function (rowBody): string {
          return rowBody.value;
        });
      });

      return {
        columns,
        rows,
      } as StoryblokTable;

    default:
      return field;
  }
}

/**
 * checks the properties publish_start and publish_end (if they are set)
 *
 * @param component
 */
export function checkTimedPublishingFields(component): boolean
{
  const currentTime = new Date();

  if (component.hasOwnProperty('publish_start')) {
    const publishStart = new Date(component.publish_start);


    if (!isNaN(publishStart.getTime()) && publishStart > currentTime) {
      return false;
    }
  }

  if (component.hasOwnProperty('publish_end')) {
    const publishEnd = new Date(component.publish_end);


    if (!isNaN(publishEnd.getTime()) && publishEnd <= currentTime) {
      return false;
    }
  }

  return true;
}


/**
 * Parses meta information from the given content and applies it to the meta information
 *
 * @param meta
 * @param content
 */
export function applyMetaFromContent(meta, content): void
{
  let defaultTitle = 'Karriere bei Möbel Rogg';

  if (content === void 0) {
    return;
  }

  if (content.hasOwnProperty('title') && content.title !== '') {
    meta.title = content.title;
  } else if (content.hasOwnProperty('meta_title') && content.meta_title !== '') {
    meta.title = content.meta_title;
  } else {
    meta.title = defaultTitle;
  }

  if (content.hasOwnProperty('canonical_url')) {
    meta.link.push(
      {
        rel: 'canonical',
        href: content.canonical_url,
      },
    );
  }

  if (content.hasOwnProperty('meta_description')) {
    meta.meta.push(
      {
        name: 'description',
        content: content.meta_description,
      },
    );
  }

  if (content.hasOwnProperty('meta_keywords')) {
    meta.meta.push(
      {
        name: 'keywords',
        content: content.meta_keywords,
      },
    );
  }

  if (content.hasOwnProperty('robots')) {
    const robotsArray = content.robots;

    if (Array.isArray(robotsArray)) {
      const robots = robotsArray.join(', ');
      meta.meta.push(
        {
          name: 'robots',
          content: robots,
        },
      );
    }
  }
}
