

import BaseIcon from '~/components/BaseIcon.vue';

export default {
  name: 'BaseMobileNavigationMenu',
  components: {
    BaseIcon,
  },
  props: {
    links: {
      type: Array,
    },
  },
  data() {
    return {
      currentLink: this.$nuxt.$route.path,
    };
  },
  methods: {
    closeMenu: function() {
      this.$emit('onClose');
    },
  },
};
