
import InlineSvg from 'vue-inline-svg';
import { computed, defineComponent } from '@nuxtjs/composition-api';

import debounce from '~/helpers/debounce';
import { useJobs } from '~/integrations/softgarden/src/composables/useJobs';
import { useContent } from '@vue-storefront/storyblok';

export default defineComponent({
  name: 'BaseSearchBar',
  components: { InlineSvg },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    debounceDuration: {
      type: Number,
      default: 100,
    },
    classes: {
      type: String,
    },
  },
  setup() {
    const {
      jobs,
      loading: loadingJobs,
      error: jobsError,
      search: searchJobs,
    } = useJobs();

    const {
      search: sbSearch,
      content: sbContent,
      loading: sbLoading,
    } = useContent();

    return {
      jobs,
      loadingJobs,
      searchJobs,
      sbSearch,
      sbContent,
    };
  },
  data() {
    return {
      searchTerm: '',
      searchResultJobs: [],
      searchResultStoryblok: [],
    };
  },
  watch: {
    async searchTerm() {
      // debounce and emit event with search term to the parent component
      debounce(async function() {
        this.$emit('onSearch', this.searchTerm);

      }.bind(this), this.debounceDuration)();
    },
  },
  methods:{
    async jobSearch(searchTerm) {

      if (searchTerm.length < 3) {
        this.searchResultJobs = [];
      }

      await this.searchJobs({name: this.searchTerm});
      this.searchResultJobs = this.jobs;
    },
    async storyblokSearch(searchTerm) {

      if (searchTerm.length < 3) {
        this.searchResultStoryblok = [];
      }

      await this.sbSearch({
        'custom': {
          'search_term': searchTerm,
          'filter_query': {
            'component': {
              'in': 'cms_page',
            },
          },
        },
        cache: false,
      });

      this.searchResultStoryblok = [];

      if (typeof this.sbContent === 'object') {
        Object.keys(this.sbContent).map((key) => {
          if (!isNaN(Number(key))) {
            this.searchResultStoryblok[Number(key)] = this.sbContent[key];
          }
        });
      } else {
        this.searchResultStoryblok = this.sbContent;
      }
    },
    clearSearchTerm: function() {
      this.searchTerm = '';
      this.$emit('onSearch', this.searchTerm);
    },
  },
});
