
import Vue from 'vue';
import BaseIcon from '@/components/BaseIcon.vue';

export default Vue.extend({
  name: 'BaseButton',
  components: {
    BaseIcon,
  },
  props: {
    classes: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
    },
    icon: {
      type: String,
    },
    label: {
      type: String,
    },
    target: {
      type: String,
      default: '_self',
    },
    styles: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'secondary', 'text-link'].includes(value),
    },
    tag: {
      type: String,
      default: 'a',
      validator: (value) => ['button', 'a', 'link'].includes(value),
    },
  },
  methods: {
    mapClasses: (styles) => {
      let classes = '';
      switch(styles) {
        case 'primary':
          classes = 'btn-primary flex items-center px-m py-xs bg-cta mr-h4-reg text-white shadow-[0_2px_4px_0_rgba(102,102,102,0.4)] ' +
            'rounded-sm btn-icon-primary justify-center w-full tablet:justify-start tablet:w-fit';
          break;
        case 'secondary':
          classes = 'btn-secondary flex items-center px-m py-xs bg-white mr-h4-reg text-mr-primary shadow-[0_2px_4px_0_rgba(102,102,102,0.4)] ' +
            'rounded-sm border-cta border btn-icon-secondary justify-center w-full tablet:justify-start tablet:w-fit';
          break;
        case 'text-link':
          classes = 'btn-text-link flex items-center text-cta mr-underline-reg font-bold btn-icon-text-link';
          break;
      }
      return classes;
    },
    callback: function(e) {
      this.$emit('click', e);
    },
  },
});
