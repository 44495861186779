
export default {
  name: 'LoadingCircle',
  props: {
    spacing: {
      type: String,
      default: 'xxs',
    },
    classes: {
      type: String,
    },
  },
};
