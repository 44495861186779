var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{'nav-fixed': _vm.isMobileSearchVisible}},[_c('div',{staticClass:"shadow-[0_1px_4px_1px_#EEEEEF] relative z-40"},[_c('div',{staticClass:"header-navigation-wrapper mx-[auto]"},[_c('div',{staticClass:"tablet:flex tablet:items-center header-nav-bar p-m tablet:px-0"},[_c('a',{staticClass:"block mx-auto header-nav-logo tablet:my-auto",attrs:{"href":"/","title":"Zur&uuml;ck zur Startseite"}},[(_vm.header.logo.component === 'BaseIcon')?_c('BaseIcon',{attrs:{"name":_vm.header.logo.name,"height":"100%","width":"100%"}}):(_vm.header.logo.component === 'PictureComponent')?_c('img',{staticClass:"h-full mx-auto",attrs:{"src":_vm.header.logo.image.filename,"alt":"Logo"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"tablet:block grow"},[_c('div',[_c('div',[_c('BaseSearchBar',{ref:"searchBar",attrs:{"debounce-duration":_vm.debounceDuration,"classes":_vm.isMobileSearchVisible ? 'show' : ''},on:{"onSearch":_vm.onSearch}})],1),_vm._v(" "),(_vm.isMobileSearchVisible || (_vm.windowWidth > 768 && _vm.searchTerm.length > 0))?_c('div',[_c('div',[_c('BaseSearchResultContainer',{ref:"searchBarContainer",attrs:{"search-term":_vm.searchTerm}})],1)]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"hidden tablet:flex tablet:flex-row h-m"},[_vm._l((_vm.header.directLinks),function(directLink,index){return _c('a',{key:'header-link-icon-' + index,staticClass:"hidden tablet:block md:invisible mr-m w-m hover:text-mr-primary",class:{
              'text-mr-primary': _vm.isLinkActive(directLink.url.cached_url),
              'text-black-gray-40': !_vm.isLinkActive(directLink.url.cached_url),
            },attrs:{"href":(directLink.url.linktype === 'story' ? '/' : '') + directLink.url.cached_url}},[_c('InlineSvg',{attrs:{"src":'/icons/' + directLink.icon + '.svg',"height":"1.5rem","width":"1.5rem"}})],1)}),_vm._v(" "),_vm._l((_vm.header.directLinks),function(directLink,index){return _c('a',{key:'header-link-text-' + index,staticClass:"hidden md:block mx-s mr-subline-lrg hover:text-mr-primary",class:{
              'text-mr-primary': _vm.isLinkActive(directLink.url.cached_url),
              'text-black-gray-40': !_vm.isLinkActive(directLink.url.cached_url),
            },attrs:{"href":(directLink.url.linktype === 'story' ? '/' : '') + directLink.url.cached_url}},[_vm._v("\n            "+_vm._s(directLink.title)+"\n          ")])}),_vm._v(" "),_c('button',{staticClass:"navigation-burger-btn hidden w-[24px] tablet:block md:hidden md:w-0 md:h-0",attrs:{"type":"button"},on:{"click":_vm.toggleMobileNavigation}},[(!_vm.isMobileNavigationVisible)?_c('InlineSvg',{attrs:{"src":"/icons/hamburger.svg","height":"100%","width":"100%"}}):_vm._e()],1)],2)]),_vm._v(" "),(_vm.header.navigation.length > 0)?_c('div',{staticClass:"header-navigation flex-row pb-m"},_vm._l((_vm.header.navigation),function(navigationLink,index){return _c('a',{key:'header-direct-link-' + index,staticClass:"mx-s mr-h4-reg hover:text-mr-primary",class:{
            'text-mr-primary': _vm.isLinkActive(navigationLink.url.cached_url),
            'text-black-gray-40': !_vm.isLinkActive(navigationLink.url.cached_url),
          },attrs:{"href":(navigationLink.url.linktype === 'story' ? '/' : '') + navigationLink.url.cached_url}},[_vm._v("\n          "+_vm._s(navigationLink.title)+"\n        ")])}),0):_vm._e()])]),_vm._v(" "),(_vm.isMobileSearchVisible)?_c('div',{staticClass:"container mx-[auto] relative h-[52px]"}):_vm._e(),_vm._v(" "),(_vm.isMobileNavigationVisible)?_c('div',{staticClass:"bg-white w-[100vw] h-[calc(100%-70px)] tablet:h-[100vh] fixed top-0 mb-bottom-bar z-50 overflow-scroll"},[_c('BaseMobileNavigationMenu',{attrs:{"links":_vm.header.navigation},on:{"onClose":_vm.closeNavigation}})],1):_vm._e(),_vm._v(" "),_c('div',[_c('BaseBottomBarMobile',{ref:"bottomBar",attrs:{"icons":_vm.header.mobileBarButtons},on:{"onAction":_vm.onMobileBottomBarAction}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }