
import debounce from '~/helpers/debounce';
import LoadingCircle from '~/components/LoadingCircle.vue';
import { useJobs } from '~/integrations/softgarden/src/composables/useJobs';
import { useContent } from '@vue-storefront/storyblok';

export default {
  name: 'BaseSearchResultContainer',
  components: {
    LoadingCircle,
  },
  props: {
    searchTerm: {
      type: String,
      default: '',
    },
    debounceDuration: {
      type: Number,
      default: 700,
    },
  },
  setup() {
    const {
      jobs,
      loading: loadingJobs,
      error: jobsError,
      search: searchJobs,
    } = useJobs();

    const {
      search: sbSearch,
      content: sbContent,
      loading: loadingContent,
    } = useContent();

    return {
      jobs,
      loadingJobs,
      searchJobs,
      sbSearch,
      sbContent,
      loadingContent,
    };
  },
  data() {
    return {
      totalSearchResult: 0,
      showContentResult: false,
      showJobResult: false,
      searchResultStoryblok: [],
      searchResultJobs: [],
    };
  },
  watch: {
    searchTerm: function() {
      if (!this.searchTerm || this.searchTerm.length <= 3) {
        this.searchResultJobs = [];
        this.searchResultStoryblok = [];
        return;
      }

      debounce(async function() {
        this.jobSearch();
        this.storyblokSearch();
      }.bind(this), this.debounceDuration)();
    },
  },
  methods: {
    highlightSearchTerm(string) {
      let regex = new RegExp('\\w*'+this.searchTerm+'\\w*', 'ig');
      let subStrings = string.match(regex) || [];
      let newString = string;

      if (subStrings.length > 0) {
        subStrings.forEach(substring => {
          let substringRegEx = new RegExp(substring);
          newString = newString.replace(substringRegEx, '<span class="text-mr-primary font-semibold">'+substring+'</span>');
        });
      }

      return newString;
    },
    toggleContentResult: function() {
      this.showContentResult = true;
      this.showJobResult = false;
    },
    toggleJobResult: function() {
      this.showContentResult = false;
      this.showJobResult = true;
    },
    calcTotalSearchResult: function() {
      let searchResult = 0;

      searchResult += this.searchResultStoryblok.length || 0;
      searchResult += this.searchResultJobs.length || 0;

      this.totalSearchResult = searchResult;
    },
    async jobSearch() {
      this.searchResultJobs = [];

      await this.searchJobs(
        {name: this.searchTerm},
      );

      this.searchResultJobs = this.jobs;
      this.calcTotalSearchResult();
    },
    async storyblokSearch() {
      this.searchResultStoryblok = [];

      await this.sbSearch({
        'custom': {
          'search_term': this.searchTerm,
          'filter_query': {
            'component': {
              'in': 'cms_page',
            },
          },
        },
        cache: false,
      });

      this.searchResultStoryblok = [];

      if (typeof this.sbContent === 'object') {
        Object.keys(this.sbContent).map((key) => {
          if (!isNaN(Number(key))) {
            this.searchResultStoryblok[Number(key)] = this.sbContent[key];
          }
        });
      } else {
        this.searchResultStoryblok = this.sbContent;
      }
      this.calcTotalSearchResult();
    },
  },
};
