

import { onSSR } from '@vue-storefront/core';
import { useContent } from '@vue-storefront/storyblok';
import InlineSvg from 'vue-inline-svg';
import { ssrRef } from '@nuxtjs/composition-api';
import BaseIcon from '~/components/BaseIcon.vue';
import BaseSearchBar from '~/components/BaseSearchBar.vue';
import BaseBottomBarMobile from '~/components/BaseBottomBarMobile.vue';

export default {
  name: 'TheHeaderNavigation',
  components: {
    InlineSvg,
    BaseIcon,
    BaseSearchBar,
    BaseBottomBarMobile,
  },
  props: {
    debounceDuration: {
      type: Number,
      default: 100,
    },
  },
  setup () {
    const { search, content, loading, error } = useContent('TheHeader');

    let header = ssrRef({
      navigation: [],
      directLinks: [],
      logo: {
        component: 'BaseIcon',
        name: 'moero-logo-1',
      },
      mobileBarButtons: [],
    });

    onSSR(async () => {
      // fetch header as static story
      await search({url: 'static/header'});

      if (typeof content.value !== 'object' || Object.keys(content.value).length === 0) {
        return;
      }

      const rawHeader = content.value;

      // replace default / placeholder data with Storeblock data
      header.value.navigation = rawHeader.navigation;
      header.value.directLinks = rawHeader.directLinks;
      header.value.logo = rawHeader.logo[0];

      // parse mobile bottom bar
      const mobileBarButtons = [];
      rawHeader.mobileBarButtons.forEach((function(rawButton) {
        const iconName = rawButton.icon;
        const action = rawButton.action;

        if (action === 'link') {
          mobileBarButtons.push(
            {
              icon: iconName,
              action: action,
              url: (rawButton.link.linktype === 'story' ? '/' : '') + rawButton.link.cached_url,
            },
          );
          return;
        }

        mobileBarButtons.push(
          {
            icon: iconName,
            action: action,
          },
        );
      }));
      header.value.mobileBarButtons = mobileBarButtons;
    });

    // return data
    return {
      loading,
      header,
    };
  },
  data() {
    return {
      isMobileSearchVisible: false,
      isMobileNavigationVisible: false,
      search: {
        searchTerm: '',
        jobs: [],
        content: [],
      },
      searchTerm: '',
      windowWidth: 0,
      pathname: '',
    };
  },
  watch: {
    isMobileNavigationVisible: function() {
      this.updateNoScroll();
    },
    isMobileSearchVisible: function() {
      this.updateNoScroll();
    },
    search: function() {
      this.updateNoScroll();
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    this.pathname = window.location.pathname;
    document.addEventListener('resize', this.onResize);
  },
  destroyed() {
    document.removeEventListener('resize', this.onResize);
  },
  methods: {
    onMobileBottomBarAction: function(action) {
      switch(action) {
        case 'search':
          this.toggleMobileSearchbar();
          break;
        case 'navigation':
          this.toggleMobileNavigation();
          break;
        default:
          this.isMobileSearchVisible = false;
          this.isMobileNavigationVisible = false;
          this.$refs.bottomBar.deselectIcon();
          break;
      }
      this.updateNoScroll();
    },
    onSearch: function(searchResult) {
      this.searchTerm = searchResult;
    },
    toggleMobileSearchbar: function() {
      this.isMobileSearchVisible = !this.isMobileSearchVisible;
      this.isMobileNavigationVisible = false;
      !this.isMobileSearchVisible ? this.$refs.bottomBar.deselectIcon() : '';
    },
    toggleMobileNavigation: function() {
      this.isMobileNavigationVisible = !this.isMobileNavigationVisible;
      this.isMobileSearchVisible = false;
      !this.isMobileNavigationVisible ? this.$refs.bottomBar.deselectIcon() : '';
    },
    updateNoScroll: function() {
      const noScroll = this.isMobileNavigationVisible || this.isMobileSearchVisible;
      document.body.classList.toggle('body-no-scroll', noScroll);
    },
    closeNavigation: function() {
      this.isMobileNavigationVisible = false;
      this.$refs.bottomBar.deselectIcon();
    },
    onResize: function () {
      this.windowWidth = window.innerWidth;
    },
    isLinkActive: function(url) {
      let regex = new RegExp(url+'\\b', 'gmi');
      return this.pathname.match(regex)?.length > 0;
    },
  },
};
