

import {onSSR} from '@vue-storefront/core';
import {useContent} from '@vue-storefront/storyblok';
import BaseIcon from '~/components/BaseIcon.vue';
import RenderContent from '@/components/RenderContent.vue';
import {ssrRef} from '@nuxtjs/composition-api';

export default {
  name: 'TheFooter',
  components: {
    BaseIcon,
    RenderContent,
  },
  setup() {
    const {search, content, loading} = useContent('footer');

    let currentYear = ssrRef(new Date().getFullYear());

    let footer = ssrRef({
      addressHeadline: 'Adresse',
      address: 'Möbel Rogg Balingen \nGmbH & Co. KG\nWiderholstraße 20\n72336 Balingen',
      legalHeadline: 'Rechtliches',
      legalLinks: [],
      companyHeadline: 'Das Unternehmen',
      companyLinks: [],
      paymentIcons: [],
      copyright: `© Möbel Rogg Balingen GmbH & Co. KG. 1938-${currentYear.value}`,
      firstPicture: [],
      secondPicture: [],
    });

    onSSR(async () => {
      // fetch footer as static story
      await search({url: 'static/footer'});

      if (typeof content.value !== 'object' || Object.keys(content.value).length === 0) {
        return;
      }

      const rawFooter = content.value;
      footer.value.address = rawFooter.address;
      footer.value.addressHeadline = rawFooter.address_headline;
      footer.value.legalHeadline = rawFooter.legal_headline;
      footer.value.legalLinks = rawFooter.legal_links;
      footer.value.companyHeadline = rawFooter.company_headline;
      footer.value.companyLinks = rawFooter.company_links;
      footer.value.paymentIcons = rawFooter.payment_icons[0].icons;
      footer.value.copyright = `© Möbel Rogg Balingen GmbH & Co. KG. 1938-${currentYear.value}`;
      footer.value.firstPicture = rawFooter.first_picture;
      footer.value.secondPicture = rawFooter.second_picture;
    });

    // return data
    return {
      loading,
      footer,
    };
  },
  methods: {
    onClickHandler(e, cb) {
      if (cb && typeof window[cb] === 'function') {
        e.preventDefault();
        // @ts-ignore
        window[cb]();
      }
    },
    toggleFooterServiceLinks(e) {
      let serviceLinkInputs = document.querySelectorAll('[name=footer-service-links]');
      serviceLinkInputs.forEach(serviceLink => {
          if (serviceLink !== e.target) {
            // @ts-ignore
            serviceLink.checked = false;
          }
      });
    },
    getHrefForLink(link) {
      let href = '';

      if (link.url.url && link.url.url !== '') {
        href = link.url.url;
      } else if (link.url.cached_url && link.url.cached_url !== '') {
        href = `/${link.url.cached_url}`;
      }

      return href;
    },
  },
};
